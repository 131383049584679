import React, {useMemo, useState} from 'react';
import QRCode from 'qrcode.react';

const QRCodeGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [generate, setGenerate] = useState(false);

  const handleChange = (event) => {
    setInputText(event.target.value);
    setGenerate(false);
  };

  const handleGenerate = () => {
    setGenerate(true);
  };

  // Dynamic sizing based on text length
  const qrSize = useMemo(() => {
    const baseSize = 256; // Base size for the QR code
    const length = inputText.length;
    if (length > 500) return baseSize * 1.5;
    if (length > 1000) return baseSize * 2;
    if (length > 2000) return baseSize * 2.5;

    return baseSize;
  }, [inputText]);

  return (
    <div style={{padding: "10px"}}>
      <h1>QR Code Generator</h1>
      <h2>100% on-device generation. Strong privacy.</h2>
      <p>Limitation: About 2900 english characters.</p>
      <textarea
        value={inputText}
        onChange={handleChange}
        rows="10"
        cols="80"
        placeholder="Enter text here..."
      />
      <br/>
      <button onClick={handleGenerate}>Generate QR Code</button>
      <br/>
      <br/>
      {generate && <QRCode value={inputText} size={qrSize} />}    </div>
  );
};

export default QRCodeGenerator;
